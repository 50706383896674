import styled from "styled-components";

export const MainDiv = styled.div`
  margin: 2% 10%;
  padding: 0;

  @media (max-width: 768px) {
    margin: 2% 0%;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: auto auto auto auto;
  //grid-gap: 50px;
  grid-gap: 20px;
  @media (max-width: 768px) {
    display:none;
  }
`;

export const CardContainerMobile = styled.div`
display: none;
@media (max-width: 768px) {
  display:grid;
  justify-items: center;
  grid-gap: 20px;
  grid-template-columns: auto;
}
`;

export const Card = styled.div`
  margin-bottom: 35px;
  @media (max-width: 768px) {
    padding: 25px;
    margin-bottom: 0px;
    scroll-margin:95px;
    &.active {
      background: #fff;
      box-shadow: 0px 0px 10px 1px #93939385;
    }
  }
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
  color:var(--font-color);
  margin-top: 15px;
  @media (max-width: 1440px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    text-align: center;
    font-size: 20px;
  }
`;

export const Title = styled.h3`
  color: #007dc3;
  font-size: 14px;
  @media (max-width: 1024px) {
    font-size: 9px;
  }
  @media (max-width: 768px) {
    text-align: center;
    font-size: 14px;
  }
`;

export const Info = styled.div`
  padding: 10px;
  margin-top: -10px;
  box-shadow: 0px 8px 7px 0px #b1b1b152;
  font-size: 16px;
  width: 300px;
  color:var(--font-color);
  @media (max-width: 1530px) {
    width: 220px;
  }
  @media (max-width: 1440px) {
    font-size: 12px;
  }
  @media (max-width: 1024px) {
    width: 150px;
    font-size: 10px;
  }
  @media (max-width: 768px) {
    width: 380px;
    font-size: 14px;
    box-shadow: none;
  }
  @media (max-width: 425px) {
    width: 220px;
  }
`;

export const Image = styled.img`
  width: 300px;
  @media (max-width: 1530px) {
    width: 220px;
  }
  @media (max-width: 1024px) {
    width: 150px;
  }
  @media (max-width: 768px) {
    width: 380px;
  }
  @media (max-width: 425px) {
    width: 220px;
  }
`;

export const Header = styled.h1`
  font-size: 50px;
  font-weight: bold;
  margin: 40px;
  @media (max-width: 1440px) {
    font-size: 34px;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (max-width: 425px) {
    font-size: 38px;
  }
`;

