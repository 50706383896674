import * as Styled from './introCardsStyles';
import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import ContentfulAssets from '../../hooks/useContentfulAssets';
import './introCards.css';
import BlueLine from '../blueLine/blueLine';
//Get column rows
const getRows=(n)=>{
    let rows=0;
    let number =0+(n%4);
    while(n>=number){
        number+=4;
        rows++;
    }
    return rows;
}

//Arrange Data in column wise manner
const getData=(teamData)=>{
        const len = teamData.length;
        const rows = getRows(len);
        const ModifiedArray = [];
        let temp = [];
        for(var id=0;id<len;id++){
            if(teamData[id] && teamData[id].elementId!==''){
                teamData[id].elementId=id;
            }
        }
        
        for(var columnNumber=0;columnNumber<4;columnNumber++){
            var columnRowCouner=0;
            
            for(var rownumber = 1;rownumber<=rows;rownumber++){
                temp.push(teamData[columnNumber+columnRowCouner]?teamData[columnNumber+columnRowCouner]:null);
                columnRowCouner +=4;
            }
            ModifiedArray.push(temp);
            temp=[];
            
        }
    return ModifiedArray;
}

function scrollToMyDiv(element) {
    document.getElementById(element).scrollIntoView({ behavior: "smooth", block: "start" });
}

const IntroCards = ({ sectionData }) => {
    const [toggle, setToggle] = useState({});
        function toggleFunction(id) {
            setToggle({
                
                [id]: !toggle[id],
            });
            scrollToMyDiv(id);
            }
        
    const data = getData(sectionData?.list);
    
        const optionsMainStyle = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const asset = ContentfulAssets(node.data.target.sys.id);
                if (asset) {
                return (
                    <Styled.Image
                    src={asset.node.file.url}
                    alt={asset.node.title}
                    />
                );
                }
            },
            [BLOCKS.HEADING_2]: (node, children) => (
                <Styled.Name>{children}</Styled.Name>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <Styled.Title>{children}</Styled.Title>
            ),
            [BLOCKS.HEADING_5]: (node, children) => (
                <Styled.Info style={{display:'none'}}>{children}</Styled.Info>
            )
        },
        renderText: (text) =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    };

    const optionsParagraphStyle = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, children) => (
                <Styled.Name style={{display:'none'}}>{children}</Styled.Name>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <Styled.Title style={{display:'none'}}>{children}</Styled.Title>
            ),
            [BLOCKS.HEADING_5]: (node, children) => (
                <Styled.Info>{children}</Styled.Info>
            )
            
        },
        renderText: (text) =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    };
    return sectionData?.list?.length>0 && (
        <Styled.MainDiv>
            <BlueLine/>
            <Styled.Header>
                Our Team
            </Styled.Header>
            <Styled.CardContainer>
            {data?.map((item,index)=>{
                return(
                <div key={index}>
                    {
                        item?.map((bio,indexz)=>{
                                return(bio &&
                                    <Styled.Card key={bio?.elementIddex} className={`${ toggle[bio?.elementId] ? "active" : ""}`}>
                                    <button onClick={() => toggleFunction(bio?.elementId)} className={`button ${ toggle[bio?.elementId] ? "active" : ""}`}>
                                        <span></span>
                                        <span></span>
                                    </button>
                                        {documentToReactComponents(
                                        JSON.parse(bio?.contentDetails.raw),
                                        optionsMainStyle
                                        )}
                                        
                                    <p style={{ display: toggle[bio?.elementId] ? 'block' : 'none' }}>
                                    {documentToReactComponents(
                                        JSON.parse(bio?.contentDetails.raw),
                                        optionsParagraphStyle
                                        )}    
                                    </p>
                                
                                    </Styled.Card>
                                );
                            })
                    }
                </div>
                );
            })}
            </Styled.CardContainer>
            <Styled.CardContainerMobile>
                    {
                        sectionData?.list?.map((bio,indexz)=>{
                                return(bio &&
                                    <Styled.Card key={bio?.elementIndex} className={`${ toggle[bio?.elementId] ? "active" : ""}`} id={bio?.elementId}>
                                    <button onClick={() => {toggleFunction(bio?.elementId); setTimeout(() => {
                                    scrollToMyDiv(bio?.elementId);    
                                    }, 10);}} className={`button ${ toggle[bio?.elementId] ? "active" : ""}`}>
                                        <span></span>
                                        <span></span>
                                    </button>
                                        {documentToReactComponents(
                                        JSON.parse(bio?.contentDetails?.raw),
                                        optionsMainStyle
                                        )}
                                        
                                    <p style={{ display: toggle[bio?.elementId] ? 'block' : 'none' }}>
                                    {documentToReactComponents(
                                        JSON.parse(bio?.contentDetails?.raw),
                                        optionsParagraphStyle
                                        )}    
                                    </p>
                                
                                    </Styled.Card>
                                );
                            })
                    }
            </Styled.CardContainerMobile>
        </Styled.MainDiv>
    );
    };


export default IntroCards;